import {
  B2GEligibilityStatusType,
  CertificateType,
  ChemistryTestStatus,
  ExportCertificateStatus,
  PackagingStatus,
  TransportModeCode,
  WineDisputedReason,
  DestinationMarket as WinemakingMarket,
} from "@tc/graphql-server"
import {
  AirIcon,
  AllProductsIcon,
  AllRequestsIcon,
  CopyIcon,
  DeleteIcon,
  ExportRequestIcon,
  FreeSalesIcon,
  HomeIcon,
  LiveAnimalIcon,
  OrganicCertIcon,
  SeaIcon,
  WineIcon,
} from "@tc/ui-dfe/icons"
import { DestinationMarket } from "@tc/ui-dfe/query"

export * from "./defaultValues/exportRequest"

export const drawerWidth = 243
export const maxCertificateCount = 3
export const maxRecentRequestCount = 15
export const maxExportRequestProductCount = 10
export const maxExportRequestSupportingDocumentCount = 3
export const defaultDateFormat = "DD MMM YYYY"
export const defaultDateTimeFormat = "DD MMM YYYY HH:mm"

export const requestPerPage = 20
export const MenuIcons = Object.freeze({
  "/": <HomeIcon fontSize="small" />,
  "/requests": <AllRequestsIcon fontSize="small" />,
  "/products": <AllProductsIcon fontSize="small" />,
  "/export-request": <ExportRequestIcon fontSize="small" />,
})
export const YesNoOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
]

export enum AlertPortalType {
  AppPortal = "app-alert-container",
  FormPortal = "form-alert-container",
}

export const ProductItemMoreOptions = [
  {
    value: "duplicate",
    label: "Duplicate product",
    icon: <CopyIcon />,
    disabled: false,
  },
  {
    value: "remove",
    label: "Remove product",
    icon: <DeleteIcon fill="error.main" />,
    styles: {
      color: "error.main",
    },
    disabled: false,
  },
]

export const CertificateIconMap = {
  [CertificateType.FreeSale]: <FreeSalesIcon sx={{ width: "24px" }} />,
  [CertificateType.Organics]: <OrganicCertIcon sx={{ width: "24px" }} />,
  [CertificateType.LiveAnimals]: <LiveAnimalIcon sx={{ width: "24px" }} />,
  [CertificateType.FreeSaleStatement]: <FreeSalesIcon sx={{ width: "24px" }} />,
  [CertificateType.ExportEligibilityStatement]: (
    <WineIcon sx={{ width: "24px" }} />
  ),
  [CertificateType.Supplementary]: <WineIcon sx={{ width: "24px" }} />,
}

export const ExportRequestDetailsSectionLabels = Object.freeze({
  request_details: "Request details",
  transport_details: "Transport details",
  products: "Products",
  declarations: "Declarations",
  supporting_documents: "Supporting documents",
  receiving_your_certificates: "Receiving your certificates",
})

export const WineProductSectionLabels = Object.freeze({
  general_information: "General information",
  wine_processes: "Add Process",
})

export * from "./messages"

export const TransportIconMap = Object.freeze({
  [TransportModeCode.AirTransport]: (
    <AirIcon fill="primary.dark" sx={{ p: 1, height: "40px", width: "40px" }} />
  ),
  [TransportModeCode.FixedTransportInstallation]: null,
  [TransportModeCode.InlandWaterTransport]: null,
  [TransportModeCode.Mail]: null,
  [TransportModeCode.MaritimeTransport]: (
    <SeaIcon fill="primary.dark" sx={{ height: "40px", width: "40px" }} />
  ),
  [TransportModeCode.MultimodalTransport]: null,
  [TransportModeCode.NotApplicable]: null,
  [TransportModeCode.NotSpecified]: null,
  [TransportModeCode.RailTransport]: null,
  [TransportModeCode.RoadTransport]: null,
})

export const CertificateLabels: Record<CertificateType, string> = {
  [CertificateType.LiveAnimals]: "Zoosanitary Horses to Australia AUPER13",
  [CertificateType.FreeSale]: "Free Sale Certification FSC 1",
  [CertificateType.FreeSaleStatement]: "Free Sale Statement FSS 1",
  [CertificateType.Organics]: "Organic Official Assurance OP504.1",
  [CertificateType.ExportEligibilityStatement]: "Export Eligibility Statement",
  [CertificateType.Supplementary]: "Supplementary Certification",
}

export const ExportEligibilityLabels: Record<B2GEligibilityStatusType, string> =
  {
    [B2GEligibilityStatusType.Eligible]: "Eligible",
    [B2GEligibilityStatusType.NotEligible]: "Not eligible",
    [B2GEligibilityStatusType.NotRequested]: "Not Requested",
    [B2GEligibilityStatusType.Requested]: "Requested",
    [B2GEligibilityStatusType.Expired]: "Expired",
    [B2GEligibilityStatusType.Incomplete]: "Incomplete",
  }

export enum CountryCode {
  Australia = "AU",
  China = "CN",
  GreatBritain = "GB",
  Japan = "JP",
  Singapore = "SG",
  Taiwan = "TW",
}

export enum CertificateSigningOfficeEnum {
  Auckland = "auckland",
  Christchurch = "christchurch",
}

export const CertificateSigningOfficeLabels: Record<
  CertificateSigningOfficeEnum,
  string
> = {
  [CertificateSigningOfficeEnum.Auckland]: "Auckland",
  [CertificateSigningOfficeEnum.Christchurch]: "Christchurch",
}

export enum CommodityEnum {
  BeeProducts = "BEE_PRODUCTS",
  DairyAndDairyProducts = "DAIRY_AND_DAIRY_PRODUCTS",
  Forestry = "FORESTRY",
  LiveAnimals = "LIVE_ANIMALS",
  MeatAndMeatProducts = "MEAT_AND_MEAT_PRODUCTS",
  NzGrapeWine = "NZ_WINE",
  OtherAnimalProducts = "OTHER_ANIMAL_PRODUCTS",
  PetFoodAndAnimalFeed = "PET_FOOD_AND_ANIMAL_FEED",
  PlantsAndPlantProducts = "PLANTS_AND_PLANT_PRODUCTS",
  ProcessedFoodAndBeverages = "PROCESSED_FOOD_AND_BEVERAGES",
  Seafood = "SEAFOOD",
}

export const CommodityLabels: Record<string, string> = Object.freeze({
  BEE_PRODUCTS: "Bee products",
  DAIRY_PRODUCTS: "Dairy and dairy products",
  FORESTRY: "Forestry",
  LIVE_ANIMALS: "Live animals",
  MEAT_PRODUCTS: "Meat and meat products",
  NZ_WINE: "NZ grape wine",
  ANIMAL_PRODUCTS: "Other animal products",
  ANIMAL_FOOD: "Pet food and animal feed",
  PLANT_PRODUCTS: "Plants and plant products",
  PROCESSED_FOOD: "Processed food and beverages",
  SEAFOOD: "Seafood",
})

export const TransportMethod: Partial<Record<TransportModeCode, string>> = {
  [TransportModeCode.AirTransport]: "Air",
  [TransportModeCode.MaritimeTransport]: "Sea",
}

export enum FileTypeEnum {
  DOCX = "docx",
  XLSX = "xlsx",
  PDF = "pdf",
  JPG = "jpg",
  PNG = "png",
}

export const FileTypeMapping: Record<
  FileTypeEnum,
  { accept: string; match: RegExp }
> = {
  [FileTypeEnum.DOCX]: {
    accept:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    match:
      /^application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document$/,
  },
  [FileTypeEnum.XLSX]: {
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    match:
      /^application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet$/,
  },
  [FileTypeEnum.PDF]: {
    accept: "application/pdf",
    match: /^application\/pdf$/,
  },
  [FileTypeEnum.JPG]: {
    accept: "image/jpeg",
    match: /^image\/(jpeg|jpg)$/,
  },
  [FileTypeEnum.PNG]: {
    accept: "image/png",
    match: /^image\/png$/,
  },
}

export enum ExportRequestAction {
  Create = "Create",
  Draft = "Draft",
  SaveProduct = "SaveProduct",
  Review = "Review",
  Submit = "Submit",
  Update = "Update",
}

export enum SensoryProcessDeclarationResponse {
  false = "Confirmed",
  true = "Disputed",
}

export const DisputedReason: Record<WineDisputedReason, string> = {
  [WineDisputedReason.Oxidised]: "Oxidised",
  [WineDisputedReason.Tainted]: "Tainted",
  [WineDisputedReason.Malodorous]: "Malodorous",
}

export enum ProcessFormButtonLabel {
  done = "Done",
  save = "Save",
  requestConfirm = "Request confirmation",
}

export enum ChemistryTestRequiredLabels {
  EUBulkAndPackagedDeAlcoholisedWine = "EU - Bulk and packaged de-alcoholised wine",
  EUBulkWine = "EU - Bulk wine",
  EUPackagedWine = "EU - Packaged wine",
  BrazilBulkAndPackagedWine = "Brazil - Bulk and packaged wine",
}

export const ExportRequestStatusLabels: Record<
  ExportCertificateStatus,
  string
> = {
  [ExportCertificateStatus.Amended]: "Amended",
  [ExportCertificateStatus.Approved]: "Approved",
  [ExportCertificateStatus.Cancelled]: "Cancelled",
  [ExportCertificateStatus.Draft]: "Draft",
  [ExportCertificateStatus.PendingReplacement]: "Pending Replacement",
  [ExportCertificateStatus.ProvisionallyApproved]: "Provisionally Approved",
  [ExportCertificateStatus.Raised]: "Raised - Pending Certification",
  [ExportCertificateStatus.Replaced]: "Replaced",
  [ExportCertificateStatus.RequestedToReplace]: "Requested To Replace",
  [ExportCertificateStatus.RequestedToResubmit]: "Requested To Resubmit",
  [ExportCertificateStatus.RequestedToRevoke]: "Approved - Requested to Revoke",
  [ExportCertificateStatus.Resubmitted]: "Resubmitted",
  [ExportCertificateStatus.Revoked]: "Revoked",
  [ExportCertificateStatus.Verified]: "Raised - Pending Certification",
}

export const PackagingStatusLabels = Object.freeze({
  [PackagingStatus.NotRequested]: "Not requested",
  [PackagingStatus.Requested]: "Requested",
  [PackagingStatus.Confirmed]: "Confirmed",
  [PackagingStatus.Disputed]: "Disputed",
})

export const WinemakingMarketLabels = Object.freeze({
  [WinemakingMarket.NotApplicable]: "General",
  // [WinemakingMarket.Australia]: "Australia",
  [WinemakingMarket.Brazil]: "Brazil",
  // [WinemakingMarket.China]: "China",
  [WinemakingMarket.Eu]: "European Union",
  [WinemakingMarket.GreatBritain]: "Great Britain",
  // [WinemakingMarket.Japan]: "Japan",
  // [WinemakingMarket.Singapore]: "Singapore",
  // [WinemakingMarket.Taiwan]: "Taiwan",
})

export const WinemakingStatusDescription = Object.freeze({
  [WinemakingMarket.NotApplicable]:
    "I confirm that this wine batch was made under a valid WSMP and the details are correct",
  [WinemakingMarket.GreatBritain]:
    "I confirm that the details and declarations provided are correct",
  [WinemakingMarket.Eu]:
    "I confirm that the details and declarations provided are correct",
  [WinemakingMarket.Brazil]:
    "I confirm that the details and declarations provided are correct",
})

export enum WinePackagingConfirmationDecision {
  true = "Confirm",
  false = "Dispute",
}

export enum WinemakingConfirmationDecision {
  true = "Confirmed",
  false = "Disputed",
  null = "Not applicable",
}

export const ChemistryTestStatusLabel = {
  [ChemistryTestStatus.Cancelled]: "Cancelled",
  [ChemistryTestStatus.Fail]: "Fail",
  [ChemistryTestStatus.InProgress]: "In progress",
  [ChemistryTestStatus.Pass]: "Pass",
  [ChemistryTestStatus.Requested]: "Requested",
  [ChemistryTestStatus.Unsuitable]: "Unsuitable",
  [ChemistryTestStatus.CancellationRequested]: "Cancellation Requested",
  [ChemistryTestStatus.Error]: "Error",
}

export const alphanumeric = /^[a-zA-Z0-9_ ]*$/

export const alphanumericAndSpecialCharacters =
  //eslint-disable-next-line
  /^[a-zA-Z0-9\/_\\.;:&+',\-() ]*$/

export const characterCode32to126 =
  // code 32 to 126 except pipe(|)
  /^[^|][\x20-\x7EÉÖàáãäèéñòóôöùüĀÀÂÁÃÄÆÇĒÈÊËĪÎÍÏÑŌÒÔÓÕŒŪÙÛÚÜŸāâæçēêëīîíïōõœūûúÿ][^|]*$/

export const destinationMarketList = [
  DestinationMarket.Eu,
  DestinationMarket.GreatBritain,
  DestinationMarket.Brazil,
]

export const KB = 1024
export const MB = KB * KB
export const defaultMaxFileSize = 10 * MB

export enum FileErrorType {
  FileSize = "FileSize",
  FileType = "FileType",
  FileUpload = "FileUpload",
  FileName = "FileName",
}
